import React, { useEffect } from 'react';

import { tracking } from '@grid-is/tracking';

import { Error404 } from '@/components/Error/Error404';

function Custom404 () {
  useEffect(() => {
    tracking.logEvent('Page Not Found Displayed', {
      error_code: 404,
      current_page: '/404',
    });
  }, [ ]);
  return <Error404 />;
}

export default Custom404;
